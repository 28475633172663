import React, { Component, useState } from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import { FormattedMessage } from '../../../util/reactIntl';
import { lazyLoadWithDimensions } from '../../../util/contextHelpers';

import { IconReviewStar, NamedLink } from '../../../components';

import css from './SectionFilteredSearches.module.css';

// Update images by saving images to src/LandingPage/SeactionFilteredSearches/images directory.
// If those images have been saved with the same name, no need to make changes to these imports.
import imageForFilter1 from './images/r-architecture-KQPEhYweLrQ-unsplash.jpg';
import imageForFilter2 from './images/r-architecture-JvQ0Q5IkeMM-unsplash.jpg';
import imageForFilter3 from './images/ralph-ravi-kayden-2d4lAQAlbDA-unsplash.jpg';
import imageForFilter4 from './images/r-architecture-wJAOeXvxudM-unsplash.jpg';
import { ReactComponent as Star } from './images/Icon ionic-ios-star-outline.svg';
import { ReactComponent as Cut } from '../../../assets/cut.svg';
import Slider from 'react-slick';
import { createResourceLocatorString } from '../../../util/routes';
import routeConfiguration from '../../../routing/routeConfiguration';
import { BsCheckCircleFill } from 'react-icons/bs';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { apiBaseUrl } from '../../../util/api';
import axios from 'axios';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { priceData } from '../../ListingPage/ListingPageHeroImage';

// Thumbnail image for the search "card"
class ThumbnailImage extends Component {
  render() {
    const { alt, ...rest } = this.props;
    return <img alt={alt} {...rest} />;
  }
}
// Load the image only if it's close to viewport (user has scrolled the page enough).
const LazyImage = lazyLoadWithDimensions(ThumbnailImage);

// Create a "card" that contains a link to filtered search on SearchPage.
export const FilterLink = (props) => {
  const {
    name,
    image,
    imageHover,
    link,
    countryName,
    price,
    cardRootClassname,
    id,
    history,
    fromAdmin,
    isVerified,
    intl,
    currentUser,
    isAuthenticated,
    rating,
    l,
  } = props;

  // const isFavourite = !!currentUser.attributes.profile.privateData.favourites?.includes(id);
  const nameText = <span className={css.searchName}>{name}</span>;
  const CountryNameText = <span className={css.countryNames}>{countryName}</span>;
  const { formattedPrice } = priceData(price, intl);
  const priceDetail = <span className={css.price}>{formattedPrice}</span>;
  const slug = encodeURI(name);
  const [isFavourite, setIsFavourite] = useState(props.isFavourite);
  const [saving, setSaving] = useState(false);
  const isOwnListing = l?.author?.id?.uuid === currentUser?.id?.uuid;
  // console.log(111, isOwnListing, currentUser, l);
  const to = fromAdmin
    ? createResourceLocatorString(
        'ViewListingPage',
        routeConfiguration(),
        { slug, id, tab: 'details' },
        {}
      )
    : createResourceLocatorString('ListingPage', routeConfiguration(), { slug, id }, {});
  const handleDetailClick = () => {
    history.push(to);
  };
  const over = (e) => {
    e.currentTarget.src = imageHover;
  };
  const out = (e) => {
    e.currentTarget.src = image;
  };
  return (
    <div className={`${css.searchLink} ${cardRootClassname}`} key={id}>
      <div className={css.imageWrapper}>
        <div className={css.aspectWrapper}>
          {/* {!isVerified ? (
            <span className={css.tagspan}>
              {' '}
              <Cut />
              Un-Verified{' '}
            </span>
          ) : (
            <span className={css.tagspan}>
              {' '}
              <BsCheckCircleFill />
              Verified{' '}
            </span>
          )} */}
          {!isOwnListing ? (
            <span
              className={css.saveButton}
              onClick={(e) => {
                if (!isAuthenticated) {
                  return history.push(
                    createResourceLocatorString('LoginPage', routeConfiguration(), {}, {})
                  );
                }
                e.preventDefault();
                e.stopPropagation();
                setSaving(true);
                const url = `${apiBaseUrl()}/api/toggleFavourites`;
                const data = { listingId: id };
                axios.post(url, data, { withCredentials: true }).then(() => {
                  setSaving(false);
                  setIsFavourite(!isFavourite);
                });
              }}
            >
              {' '}
              {isFavourite ? <AiFillStar /> : <AiOutlineStar />}
              {saving ? 'Saving...' : isFavourite ? 'Saved' : 'Save'}
            </span>
          ) : null}

          <img
            src={image}
            onMouseOver={over}
            onMouseOut={out}
            alt={name}
            className={css.searchImage}
          />
        </div>
        <div>
          <div className={css.linkText}>
            <FormattedMessage
              id="SectionFilteredSearches.filteredSearch"
              values={{ filter: nameText }}
            />
            {typeof rating !== 'undefined' ? (
              <div className={css.rtedv}>
                {[1, 2, 3, 4, 5].map((star) => (
                  <IconReviewStar key={`star-${star}`} isFilled={star <= rating} />
                ))}
              </div>
            ) : (
              <div className={css.rtedv}>
                {[1, 2, 3, 4, 5].map((star) => (
                  <IconReviewStar key={`star-${star}`} />
                ))}
              </div>
            )}
          </div>

          <div className={css.CountryText}>{CountryNameText}</div>
          <div className={css.priceDetail}>
            <FormattedMessage
              id="SectionFilteredSearches.filteredSearch"
              values={{ filter: priceDetail }}
            />
          </div>
          {/* <div>

          <NamedLink className={css.buttonDetail} name="ListingPage" params={{ id, slug }}>
            <FormattedMessage id="SectionFilteredSearches.buttonMessage" />
          </NamedLink>
          {/*  */}
          <button className={css.buttonDetail} onClick={handleDetailClick}>
            <FormattedMessage id="SectionFilteredSearches.buttonMessage" />
          </button>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

// Component that shows full-width section on LandingPage.
// Inside it shows 3 "cards" that link to SearchPage with specific filters applied.
const SectionFilteredSearches = (props) => {
  const {
    rootClassName,
    className,
    history,
    newArrivalListingLoading,
    newArrivalListings,
    newArrivalListingLoadErr,
    featuredListingLoading,
    featuredListings,
    featuredListingLoadErr,
    currentUser,
    isAuthenticated,
    intl,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // FilterLink props:
  // - "name" is a string that defines what kind of search the link is going to make
  // - "image" is imported from images directory and you can update it by changing the file
  // - "link" should be copy-pasted URL from search page.
  //    The domain doesn't matter, but search query does. (I.e. "?pub_brand=nike")
  return (
    <>
      <div className={classes}>
        <div className={css.title}>
          <FormattedMessage id="SectionFilteredSearches.title" />
        </div>
        <div className={css.subtitle}>
          <FormattedMessage id="SectionFilteredSearches.subTitle" />
        </div>

        <div className={css.filteredSearches}>
          <Slider {...settings} className="productslider">
            {featuredListings?.map((l) => {
              const firstImage =
                l?.images?.length > 0
                  ? l?.images?.[0]?.attributes.variants['default'].url
                  : imageForFilter1;
              const secondImage =
                l?.images?.length > 1
                  ? l?.images?.[1]?.attributes.variants['default'].url
                  : imageForFilter1;
              const isFavourite =
                !!currentUser?.attributes.profile.privateData.favourites?.includes(l.id.uuid);
              const { rating } = l?.attributes?.metadata || {};
              return (
                <FilterLink
                  name={l?.attributes.title}
                  l={l}
                  isFavourite={isFavourite}
                  // onmouseover
                  image={firstImage}
                  imageHover={secondImage}
                  link="http://localhost:3000/s?pub_brand=nike"
                  countryName={
                    l?.attributes?.publicData?.address.length > 50
                      ? `${l?.attributes?.publicData?.address.slice(0, 50)}...`
                      : l?.attributes?.publicData?.address
                  }
                  price={l?.attributes?.price || null}
                  history={history}
                  isVerified={!!l?.attributes?.metadata?.isVerified}
                  id={l.id.uuid}
                  key={l.id.uuid}
                  isAuthenticated={isAuthenticated}
                  currentUser={currentUser}
                  intl={intl}
                  rating={rating}
                />
              );
            })}
          </Slider>
        </div>
        {/* </Slider> */}

        <NamedLink name="SearchPage" className={css.discoverMore}>
          <FormattedMessage id="SectionFilteredSearches.discoverMore" />
        </NamedLink>
      </div>

      <div className={classes}>
        <div className={css.title}>
          <FormattedMessage id="SectionFilteredSearches.title" />
        </div>
        <div className={css.subtitle}>
          <FormattedMessage id="SectionFilteredSearches.newArrivalsubTitle" />
        </div>

        <div className={css.filteredSearches}>
          <Slider {...settings} className="productslider">
            {newArrivalListings?.map((l) => {
              const firstImage =
                l?.images?.length > 0
                  ? l?.images?.[0]?.attributes.variants['default'].url
                  : imageForFilter1;
              const secondImage =
                l?.images?.length > 1
                  ? l?.images?.[1]?.attributes.variants['default'].url
                  : imageForFilter1;
              const isFavourite =
                !!currentUser?.attributes.profile.privateData.favourites?.includes(l.id.uuid);
              const { rating } = l?.attributes?.metadata || {};
              // console.log(123454321, l);
              return (
                <FilterLink
                  name={l?.attributes.title}
                  l={l}
                  isFavourite={isFavourite}
                  // onmouseover
                  image={firstImage}
                  imageHover={secondImage}
                  link="http://localhost:3000/s?pub_brand=nike"
                  countryName={
                    l?.attributes?.publicData?.address.length > 50
                      ? `${l?.attributes?.publicData?.address.slice(0, 50)}...`
                      : l?.attributes?.publicData?.address
                  }
                  price={l?.attributes?.price}
                  history={history}
                  isVerified={!!l?.attributes?.metadata?.isVerified}
                  id={l.id.uuid}
                  key={l.id.uuid}
                  currentUser={currentUser}
                  isAuthenticated={isAuthenticated}
                  intl={intl}
                  rating={rating}
                />
              );
            })}
          </Slider>
        </div>
        <NamedLink name="SearchPage" className={css.discoverMore}>
          <FormattedMessage id="SectionFilteredSearches.discoverMore" />
        </NamedLink>
      </div>
    </>
  );
};

SectionFilteredSearches.defaultProps = { rootClassName: null, className: null };

SectionFilteredSearches.propTypes = {
  rootClassName: string,
  className: string,
};

const mapStateToProps = (state) => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.Auth;

  return {
    currentUser,
    isAuthenticated,
  };
};

export default compose(connect(mapStateToProps))(SectionFilteredSearches);
